<script setup>
	// Libraries
	import { storeToRefs } from 'pinia';

	// Composables
	import { getEnv } from '@/composables/helpers/getEnv';
	import { debounce } from '@/composables/helpers/performance';

	// Stores
	import { useGlobalStore } from '~/stores/global';

	const props = defineProps({
		initialQuery: {
			type: String,
			required: false,
			default: '',
		},
	});

	const { initialQuery } = toRefs(props);

	// Variables
	const environment = getEnv('builderEnv');
	const { searchOptions } = storeToRefs(useGlobalStore());
	const { $bus } = useNuxtApp();
	const query = ref();
	const data = ref();
	const searchInput = ref();
	const loading = ref(false);

	const index = {
		Local: 'staging_Index',
		Staging: 'staging_Index',
		Production: 'production_Index',
	};

	// Libraries
	const { search } = useAlgoliaSearch(index[environment]);

	// Computed
	const common = computed(() => {
		return {
			title: searchOptions.value.defaultSearchLabel,
			hits: searchOptions.value.commonResults.slice(0, 5),
		};
	});

	const hits = computed(() => {
		if (!data.value) return false;
		const { hits } = data.value;
		if (!hits) return false;
		return hits;
	});

	const noResults = computed(() => {
		if (!data.value) return false;
		const { hits } = data.value;
		if (!hits) return false;
		return hits.length === 0;
	});

	// Methods
	const updateResult = async () => {
		if (!query.value) {
			query.value = '';
			data.value = [];
			loading.value = false;
			return;
		}
		data.value = await search({ query: query.value });
		loading.value = false;
	};

	const onSubmit = () => {
		updateResult();
	};

	const clearSearch = () => {
		query.value = '';
		data.value = [];
	};

	// Life cycle
	watch(query, debounce(onSubmit, 500));

	onMounted(() => {
		if (initialQuery.value) query.value = initialQuery.value;
		if (query.value) updateResult(query);
	});

	useNuxtApp().hook('page:finish', () => {
		query.value = '';
		data.value = [];
	});
	$bus.on('scroll-unlock', () => {
		clearSearch();
	});

	$bus.on('open-search', () => {
		if (searchInput.value) searchInput.value.focus();
	});
</script>
<template>
	<div class="search-box">
		<form @submit.prevent="onSubmit">
			<div class="input-wrapper" :class="query && query.length ? 'has-value' : ''">
				<input
					v-model="query"
					@input="loading = true"
					type="text"
					placeholder="Search CAC"
					id="search-field"
					ref="searchInput"
				/>
				<button @click="clearSearch" title="Clear the search input">
					<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
						<path d="M17 3L4 16" stroke="currentColor" />
						<path d="M17 16L4 3" stroke="currentColor" />
					</svg>
				</button>
			</div>
			<button type="submit" @click.prevent="onSubmit" :title="`Search CAC for ${query}`">Search</button>
		</form>

		<template v-if="loading">
			<h2>Searching</h2>
			<div class="content loader">
				<MessLoading />
			</div>
		</template>
		<template v-else-if="noResults">
			<h2>No Results Found</h2>
			<div class="content">
				<p>There are 0 results for {{ query }}</p>
				<p>Try searching for something else</p>
			</div>
		</template>
		<template v-else-if="hits">
			<h2>Your Search Results</h2>
			<div class="content">
				<ul>
					<li v-for="hit in hits">
						<FragmentsAlgoliaHit :hit="hit" :key="hit.id" />
					</li>
				</ul>
			</div>
		</template>
		<template v-else>
			<h2>Common Search Results</h2>
			<div class="content">
				<ul>
					<li v-for="hit in common.hits">
						<FragmentsAlgoliaHit :hit="hit" :key="hit.id" />
					</li>
				</ul>
			</div>
		</template>
	</div>
</template>

<style lang="scss">
	.search-box {
		padding: 4rem 3rem;
		border: 1px solid var(--black);

		#search-field {
			padding: 1rem 1.5rem;
			border: 1px solid;

			color: var(--black, #000);
			font-size: 16px;
			font-style: normal;
			font-weight: 350;
			line-height: 156.1%;
			letter-spacing: 0.24px;
		}

		form {
			display: flex;
			gap: 1.5rem;

			.input-wrapper {
				flex: 1;
				position: relative;

				input {
					width: 100%;
				}

				button {
					position: absolute;
					border: 0;
					padding: 0;
					right: 1rem;
					top: 50%;
					transform: translate(0, -50%);
					color: var(--black);
					display: none;

					&:hover {
						background: transparent;
						color: var(--gray-600);
					}
				}

				&.has-value {
					button {
						display: block;
					}
				}
			}

			button {
				border: 1px solid;
				border-radius: 50px;
				padding: 1.5em 2em;
				color: var(--red, #c00);
				text-align: center;
				/* Utility/Button Label */
				font-size: 12px;
				font-style: normal;
				font-weight: 900;
				line-height: 123.1%; /* 14.772px */
				letter-spacing: 1.38px;
				text-transform: uppercase;

				transition: 250ms ease-in-out;

				&:hover {
					background: var(--red, #c00);
					color: white;
				}
			}
		}

		h2 {
			color: var(--black, #000);
			/* Headline/H4 */
			font-size: 26px;
			font-style: normal;
			font-weight: 900;
			line-height: 114.6%; /* 29.796px */
			letter-spacing: 0.39px;
			text-transform: uppercase;
			margin-top: 2rem;
		}

		.content {
			height: calc(40 * var(--vh));
			&.loader {
				display: flex;
				justify-content: center;
				align-items: center;
			}
			@media (max-width: $tablet) {
				height: calc(100% - 10rem);
			}
		}

		ul {
			@include styled-scrollbar;
			margin-top: 1rem;
			max-height: calc(40 * var(--vh));
			overflow: scroll;

			li {
				position: relative;
				user-select: none;
				+ li {
					margin-top: 1.5rem;
					padding-top: 1.5rem;

					&::before {
						content: '';
						display: block;
						width: 100%;
						height: 1px;
						background: var(--gray-500);
						position: absolute;
						top: 0;
					}
				}
			}
		}

		@media (max-width: $tablet) {
			height: 100%;

			ul {
				max-height: 100%;
			}

			form {
				button {
					display: none;
				}
			}
		}
	}
</style>
